define("zipbooks/utils/datadog-logger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.logToDataDog = logToDataDog;

  /**
   * DataDog logging utility module
   * Provides functions for logging actions to DataDog
   */

  /**
   * Log an action to DataDog
   * @param {string} title - Name of the event to log
   * @param {Object} message - Contains message to log
   * @returns {void}
   */
  function logToDataDog(title, message) {
    try {
      window.DD_RUM.onReady(function () {
        window.DD_RUM.addAction(title, message);
      });
    } catch (err) {
      console.error('Error:', err);
    }
  }
});