define("zipbooks/controllers/login", ["exports", "zipbooks/config/environment", "zipbooks/mixins/social-login-controller", "zipbooks/mixins/device-registration"], function (_exports, _environment, _socialLoginController, _deviceRegistration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_socialLoginController.default, _deviceRegistration.default, {
    client: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    overlay: Ember.inject.service(),
    errors: null,
    app: _environment.default.modulePrefix,
    view: 'login',
    accountOptions: null,
    stealth: false,
    actions: {
      login: function login() {
        this.login(this.model.getProperties('email', 'password'), false);
      },
      loginWithTfa: function loginWithTfa() {
        this.login(this.model.getProperties('email', 'password', 'code'), true);
      }
    },
    login: function login(credentials, withTfa) {
      var _this = this;

      //add the device token so threadMetrix can check the user session
      credentials['device_token'] = this.session.deviceRegisteredToken;
      this.set('buttonState', 'saving');
      this.set('errors', null);
      this.client.send('POST', 'auth', 'login', credentials).then(function (resp) {
        _this.store.pushPayload(resp);

        var identity = _this.store.peekRecord('identity', resp.data.id);

        _this.set('identity', identity);

        var stealth = resp.meta ? resp.meta.stealth : false;

        _this.set('buttonState', 'loaded');

        if (_this.get('identity.userTokens.length') === 1) {
          _this.client.send('POST', 'threatmetrix', 'verify-device', {
            token: _this.session.deviceRegisteredToken,
            email: credentials.email
          }).then(function (threatMetrixResp) {
            if (threatMetrixResp.approved) {
              _this.session.login(identity.userTokens[0].token, !!_this.get('model.remember'), stealth);

              _this.transitionToRoute('main');
            } else {
              _this.session.invalidate();

              _this.overlay.showBanner('error', 'Your login attempt was denied by our security system.', {
                title: 'Access Denied'
              });
            }
          }, function () {
            _this.session.invalidate();

            _this.overlay.showBanner('error', 'There was an error verifying your login attempt.', {
              title: 'Verification Error'
            });
          });
        } else {
          _this.set('stealth', stealth);

          _this.set('view', 'chooseAccount');
        }
      }, function (jqXHR
      /*, textStatus,errorThrown */
      ) {
        _this.set('buttonState', 'invalid');

        if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
          var errors = jqXHR.responseJSON.message;
          Object.keys(errors).forEach(function (key) {
            return errors[key] = errors[key].map(function (e) {
              return {
                message: e
              };
            });
          });

          _this.set('errors', errors);
        } else if (jqXHR.responseJSON && jqXHR.status === 422) {
          _this.set('view', 'tfa');

          if (withTfa) {
            _this.overlay.showBanner('error', "That doesn't look quite right!", {
              title: 'Oops!'
            });
          }
        } else if (jqXHR.responseJSON && jqXHR.status === 404 && jqXHR.responseJSON.error_message) {
          _this.set('view', 'login');

          _this.overlay.showBanner('error', jqXHR.responseJSON.error_message, {
            title: 'Oops!'
          });
        } else if (jqXHR.responseJSON && jqXHR.status === 404) {
          _this.set('view', 'login');

          _this.overlay.showBanner('error', "That doesn't look quite right!", {
            title: 'Oops!'
          });
        } else {
          _this.overlay.showBanner('error', 'There was some problem on our end. Sorry!', {
            title: 'Oops!'
          });
        }
      });
    }
  });

  _exports.default = _default;
});