define("zipbooks/components/drawers/credit-card-form", ["exports", "zipbooks/components/drawers/model-drawer", "zipbooks/mixins/stripe-payment-form", "zipbooks/utils/datadog-logger"], function (_exports, _modelDrawer, _stripePaymentForm, _datadogLogger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend(_stripePaymentForm.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    overlay: Ember.inject.service(),
    actions: {
      save: function save() {
        var _this = this;

        var errors = 0;

        this.model.get('errors')._clear();

        if (Ember.isBlank(this.model.get('addressLine1'))) {
          this.set('model.errors.addressLine1', [{
            message: ''
          }]);
          errors++;
        }

        if (Ember.isBlank(this.model.get('addressCity'))) {
          this.set('model.errors.addressCity', [{
            message: ''
          }]);
          errors++;
        }

        if (Ember.isBlank(this.model.get('addressState'))) {
          this.set('model.errors.addressState', [{
            message: ''
          }]);
          errors++;
        }

        if (Ember.isBlank(this.model.get('addressZip'))) {
          this.set('model.errors.addressZip', [{
            message: ''
          }]);
          errors++;
        }

        if (Ember.isBlank(this.model.get('name'))) {
          this.set('model.errors.name', [{
            message: ''
          }]);
          errors++;
        }

        if (errors > 0) {
          return;
        }

        this.set('buttonStatus', 'saving');
        var additionalData = {
          address_line1: this.model.get('addressLine1'),
          address_line2: this.model.get('addressLine2'),
          address_city: this.model.get('addressCity'),
          address_state: this.model.get('addressState'),
          address_zip: this.model.get('addressZip'),
          name: this.model.get('name') // Use Stripe.js to create a token. We only need to pass in one Element
          // from the Element group in order to create a token. We can also pass
          // in the additional customer data we collected in our form.

        };
        this.stripe.createToken(this.elements[0], additionalData).then(function (result) {
          var identities = _this.store.peekAll('identity');

          var identity = identities.length > 0 ? identities.objectAt(0) : null;
          var identityId = identity ? identity.id : null;
          (0, _datadogLogger.logToDataDog)('Stripe Card Insertion', {
            message: result,
            from: 'credit-card-form.js',
            identity_id: identityId
          });

          _this.model.set('token', result.token.id);

          _this.model.save().then(function () {
            _this.set('buttonStatus', 'loaded');

            _this.set('session.account.stripeCardId', result.token.card.id);

            _this.afterSave(_this.model);
          }, function (response) {
            _this.set('buttonStatus', 'invalid');

            _this.overlay.showBanner('error', response.errors[0].detail || 'There was an error saving your card.');
          });
        }).catch(function (response) {
          _this.set('buttonStatus', 'invalid');

          if (response.error && response.error.type === 'card_error') {
            _this.model.set('errors.' + response.error.param.camelize(), [{
              message: response.error.message
            }]);
          }
        });
      }
    },
    saveButtonTitle: Ember.computed('session.account.subscriptions.@each', function () {
      // if anything is overdue, let them know they are about to be subscribed
      if (this.get('session.subscription.isOverdue')) {
        return 'Save and subscribe now';
      } else {
        return 'Save';
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupStripe();
    }
  });

  _exports.default = _default;
});